import React from "react"
import { Carousel } from "react-responsive-carousel"
import classNames from "classnames"

import Testimonial from "./testimonial"

type Props = {
  testimonials: Queries.TestimonialFragment[]
  initialSlide: number
}

export default function TestimonialCarousel({
  testimonials,
  initialSlide
}: Props) {
  return (
    <Carousel
      className={classNames(
        "[&_.carousel-slider]:!overflow-visible",
        "[&_.carousel-slider_.slide]:px-12 lg:[&_.carousel-slider_.slide]:px-0",
        "[&_.carousel-slider_.control-arrow]:!bottom-auto [&_.carousel-slider_.control-arrow]:flex [&_.carousel-slider_.control-arrow]:rounded-full [&_.carousel-slider_.control-arrow]:!border [&_.carousel-slider_.control-arrow]:!border-solid [&_.carousel-slider_.control-arrow]:!border-primary [&_.carousel-slider_.control-arrow]:!bg-transparent [&_.carousel-slider_.control-arrow]:!p-2 [&_.carousel-slider_.control-arrow]:!text-[0] [&_.carousel-slider_.control-arrow]:!opacity-100 lg:[&_.carousel-slider_.control-arrow]:!p-4",
        "[&_.carousel-slider_.control-arrow]:!top-16 md:[&_.carousel-slider_.control-arrow]:!top-12 lg:[&_.carousel-slider_.control-arrow]:!top-10 xl:[&_.carousel-slider_.control-arrow]:!top-14 2xl:[&_.carousel-slider_.control-arrow]:!top-[4.5rem]",
        "[&_.carousel-slider_.control-arrow]:before:!m-0 [&_.carousel-slider_.control-arrow]:before:transition-all",
        "[&_.carousel-slider_.control-arrow.control-prev]:before:!-translate-x-[5px] [&_.carousel-slider_.control-arrow.control-prev]:before:border-l-8 [&_.carousel-slider_.control-arrow.control-prev]:before:border-l-transparent [&_.carousel-slider_.control-arrow.control-prev]:before:border-r-primary hover:[&_.carousel-slider_.control-arrow.control-prev]:!bg-primary hover:[&_.carousel-slider_.control-arrow.control-prev]:before:border-r-white [&_.carousel-slider_.control-arrow.control-prev]:lg:-ml-20",
        "[&_.carousel-slider_.control-arrow.control-next]:before:!translate-x-[5px] [&_.carousel-slider_.control-arrow.control-next]:before:border-r-8 [&_.carousel-slider_.control-arrow.control-next]:before:border-l-primary [&_.carousel-slider_.control-arrow.control-next]:before:border-r-transparent hover:[&_.carousel-slider_.control-arrow.control-next]:!bg-primary hover:[&_.carousel-slider_.control-arrow.control-next]:before:border-l-white [&_.carousel-slider_.control-arrow.control-next]:lg:-mr-20"
      )}
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop={true}
      selectedItem={initialSlide}
    >
      {testimonials.map((testimonial, index) => (
        <Testimonial {...testimonial} key={index} />
      ))}
    </Carousel>
  )
}
