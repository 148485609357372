import React from "react"
import { graphql } from "gatsby"

export default function TextCard({ text, color }: Queries.TextCardFragment) {
  return (
    <div
      className="flex aspect-square items-center px-3 text-xs/normal text-white sm:px-6 sm:text-sm/normal"
      style={{ backgroundColor: color }}
    >
      {text}
    </div>
  )
}

export const fragment = graphql`
  fragment TextCard on ContentfulTextCard {
    __typename
    text
    color
  }
`
