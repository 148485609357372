import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { Button } from "components"
import {
  getCookieConsentStatusFromLocalStorage,
  denyCookieConsent,
  grantCookieConsent
} from "utils"

const DELAY_BEFORE_RENDER = 1000
const DELAY_AFTER_BUTTON_CLICK = 250

export default function CookieConsentBanner() {
  const [isRendered, setIsRendered] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const showBanner = () => {
    setIsRendered(true)
    setTimeout(() => setIsVisible(true), DELAY_BEFORE_RENDER)
  }

  const hideBanner = () => {
    setIsVisible(false)
    setTimeout(() => setIsRendered(false), DELAY_AFTER_BUTTON_CLICK)
  }

  const handleAllow = () => {
    grantCookieConsent()
    hideBanner()
  }

  const handleReject = () => {
    denyCookieConsent()
    hideBanner()
  }

  useEffect(() => {
    switch (getCookieConsentStatusFromLocalStorage()) {
      case null:
        showBanner()
        break
      case true:
        grantCookieConsent()
        break
      case false:
        break
    }
  }, [])

  return isRendered ? (
    <div
      className={classNames(
        "fixed bottom-0 left-0 z-50 w-full space-y-6 bg-white/80 p-8 shadow-lg backdrop-blur-sm transition-transform sm:bottom-8 sm:left-8 sm:w-96",
        {
          "translate-x-full sm:-translate-x-[150%]": !isVisible,
          "transform-none": isVisible
        }
      )}
    >
      <div className="space-y-4 sm:text-2xs/relaxed">
        <p>
          Our website uses cookies from Google Analytics to measure performance,
          understand our audience, and enhance your experience.
        </p>

        <p>We do not collect cookies for advertising&nbsp;purposes.</p>

        <p>
          Read <Link to="/privacy-policy">our Privacy Policy</Link> to
          understand how it applies to&nbsp;cookies. After&nbsp;accepting
          to&nbsp;continue, you&nbsp;can always opt-out by clicking
          &lsquo;Cookie Settings&rsquo; at the bottom of any&nbsp;page.
        </p>
      </div>

      <div className="flex gap-x-4">
        <Button className="grow" color="success" onClick={handleAllow}>
          Allow cookies
        </Button>

        <Button className="grow" color="secondary" onClick={handleReject}>
          Reject
        </Button>
      </div>
    </div>
  ) : null
}
