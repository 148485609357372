import React from "react"
import { WithContext, Article } from "schema-dts"
import { HeadProps } from "gatsby"

import { useSiteMetadata } from "hooks"
import { useOrganization } from "components"

type Props = Omit<
  NonNullable<Queries.BlogPostQuery["contentfulPost"]>,
  "content" | "callToAction"
> &
  HeadProps["location"]
export default function PostSeo({
  title,
  pageTitle,
  pageDescription,
  author,
  publicationDate,
  media,
  pathname
}: Props) {
  const { siteUrl } = useSiteMetadata()

  const pageUrl = siteUrl + pathname
  const fullMetaImageUrl = `https:${media[0].file.url}`

  const organization = useOrganization()

  const schema: WithContext<Article> = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: title,
    url: pageUrl,
    author: {
      "@type": "Person",
      name: author.name
    },
    creator: {
      "@type": "Person",
      name: author.name
    },
    copyrightHolder: organization,
    publisher: organization,
    datePublished: publicationDate,
    copyrightYear: "2020",
    inLanguage: "en",
    description: pageDescription,
    image: {
      "@type": "ImageObject",
      url: fullMetaImageUrl
    }
  }

  return (
    <>
      <html lang={"en"} />
      <title>{`${pageTitle} • Blog by Pieoneers`}</title>

      <meta name="description" content={pageDescription} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={pageDescription} />
      <meta name="og:type" content="article" />
      <meta name="og:url" content={pageUrl} />
      <meta name="og:article:published_time" content={publicationDate} />
      <meta name="og:article:author" content={author.name} />
      <meta name="og:image" content={fullMetaImageUrl} />
      <meta
        name="og:image:width"
        content={`${media[0].file.details?.image?.width}`}
      />
      <meta
        name="og:image:height"
        content={`${media[0].file.details?.image?.height}`}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@pieoneers" />
      <meta name="twitter:creator" content="@pieoneers" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={fullMetaImageUrl} />

      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </>
  )
}
