import React from "react"
import { GatsbyBrowser } from "gatsby"

import { Layout } from "./src/components"

import "styles.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"

declare global {
  interface Window {
    gtag: (
      subject: string,
      action: string,
      parameters: Record<string, unknown>
    ) => void
  }
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props
}) => {
  return <Layout pagePath={props.location.pathname}>{element}</Layout>
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  updateCanonicalLink(location)
  sendPageView(location)
}

function updateCanonicalLink(location: Location) {
  const canonicalLink = document.querySelector(`link[rel='canonical']`)

  if (!canonicalLink) {
    console.error(`Couldn't find canonical link element.`)
    return
  }

  const canonicalLinkHref = canonicalLink.getAttribute(`href`)

  if (!canonicalLinkHref) {
    console.error(`Canonical link href is null.`)
    return
  }

  if (location.href !== canonicalLinkHref) {
    canonicalLink.setAttribute(`href`, location.href)
  }
}

function sendPageView(location: Location) {
  if (
    process.env.NODE_ENV !== `production` ||
    typeof window.gtag !== `function` ||
    !location
  ) {
    return null
  }

  const pagePath = location.pathname + location.search + location.hash

  const callPageViewEvent = () => {
    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }

  // Copied from gatsby-plugin-google-gtag source code
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(callPageViewEvent)
    })
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(callPageViewEvent, 32)
  }
}
