import Cookies from "js-cookie"
import isBrowser from "./is-browser"

declare global {
  interface Window {
    gtag: (
      subject: string,
      action: string,
      parameters: Record<string, unknown>
    ) => void
  }
}

function removeAnalyticsCookies() {
  const analyticsId = process.env.GATSBY_GOOGLE_ANALYTICS_ID?.replaceAll(
    "-",
    "_"
  )

  Cookies.remove("_ga")
  Cookies.remove("_ga", { path: "/", domain: ".pieoneers.com" })
  Cookies.remove("_gid")
  Cookies.remove("_gid", { path: "/", domain: ".pieoneers.com" })
  if (analyticsId) {
    Cookies.remove(`_gat_gtag_${analyticsId}`)
    Cookies.remove(`_gat_gtag_${analyticsId}`, {
      path: "/",
      domain: ".pieoneers.com"
    })
  }
}

const LOCAL_STORAGE_KEY = "enable-analytics"

function enableCookieConsentInLocalStorage() {
  if (isBrowser()) localStorage.setItem(LOCAL_STORAGE_KEY, "true")
}

function disableCookieConsentInLocalStorage() {
  if (isBrowser()) localStorage.setItem(LOCAL_STORAGE_KEY, "false")
}

export function getCookieConsentStatusFromLocalStorage() {
  if (!isBrowser()) return null
  const status = localStorage.getItem("enable-analytics")

  return status === null ? null : status === "true"
}

export function grantCookieConsent() {
  if (isBrowser() && window.gtag) {
    window.gtag("consent", "update", { analytics_storage: "granted" })
    enableCookieConsentInLocalStorage()
  }
}

export function denyCookieConsent() {
  if (isBrowser() && window.gtag) {
    window.gtag("consent", "update", { analytics_storage: "denied" })
    disableCookieConsentInLocalStorage()
    removeAnalyticsCookies()
  }
}
