import React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Options } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism"
import { string } from "yup"

import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript"
import typescript from "react-syntax-highlighter/dist/esm/languages/prism/typescript"
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx"
import css from "react-syntax-highlighter/dist/esm/languages/prism/css"
import prismGraphql from "react-syntax-highlighter/dist/esm/languages/prism/graphql"
import python from "react-syntax-highlighter/dist/esm/languages/prism/python"
import swift from "react-syntax-highlighter/dist/esm/languages/prism/swift"
import yaml from "react-syntax-highlighter/dist/esm/languages/prism/yaml"

type HighlightedCodeProps = Queries.HighlightedCodeFragment

export default function HighlightedCode(props: HighlightedCodeProps) {
  const { language, code } = props

  const validLanguage = validateCodeLanguage(language)

  switch (validLanguage) {
    case "javascript": {
      SyntaxHighlighter.registerLanguage(language, javascript)
      break
    }
    case "typescript": {
      SyntaxHighlighter.registerLanguage(language, typescript)
      break
    }
    case "jsx": {
      SyntaxHighlighter.registerLanguage(language, jsx)
      break
    }
    case "css": {
      SyntaxHighlighter.registerLanguage(language, css)
      break
    }
    case "graphql": {
      SyntaxHighlighter.registerLanguage(language, prismGraphql)
      break
    }
    case "python": {
      SyntaxHighlighter.registerLanguage(language, python)
      break
    }
    case "swift": {
      SyntaxHighlighter.registerLanguage(language, swift)
      break
    }
    case "yaml": {
      SyntaxHighlighter.registerLanguage(language, yaml)
      break
    }
  }

  const codeRenderOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => children
    },
    renderMark: {
      [MARKS.CODE]: text =>
        typeof text === "string" ? (
          <SyntaxHighlighter
            className="mb-4 last:mb-0 md:mb-8 [p_+_&]:md:-mt-4"
            language={validLanguage}
            style={vscDarkPlus}
            customStyle={{ margin: undefined }}
          >
            {text}
          </SyntaxHighlighter>
        ) : null
    }
  }

  return renderRichText({ raw: code.raw, references: [] }, codeRenderOptions)
}

export const fragment = graphql`
  fragment HighlightedCode on ContentfulHighlightedCode {
    language
    code {
      raw
    }
  }
`

const languages = [
  "javascript",
  "typescript",
  "jsx",
  "css",
  "graphql",
  "python",
  "swift",
  "yaml"
] as const

type Language = (typeof languages)[number]

function validateCodeLanguage(language: string): Language {
  const lowerCaseLanguage = language.toLowerCase()

  const validLanguage = string()
    .oneOf(languages)
    .defined()
    .validateSync(lowerCaseLanguage)

  return validLanguage
}
