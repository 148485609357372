import React from "react"
import classNames from "classnames"

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { isInvalid?: boolean }

export const Input = React.forwardRef<HTMLInputElement | null, InputProps>(
  (props, ref) => {
    const { className, isInvalid, ...rest } = props

    return (
      <input
        ref={ref}
        className={classNames(
          "w-full border border-default/25 px-4 py-3 leading-5 transition",
          "focus:border-default/50 focus:shadow-[0_0_0_4px] focus:shadow-default/10 focus-visible:outline-none",
          "disabled:border-default/40 disabled:bg-default/10",
          {
            "border-primary focus:border-primary focus:shadow-primary/10":
              isInvalid
          },
          className
        )}
        {...rest}
      />
    )
  }
)

type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & { isInvalid?: boolean }

export const TextArea = React.forwardRef<
  HTMLTextAreaElement | null,
  TextAreaProps
>((props, ref) => {
  const { className, isInvalid, ...rest } = props

  return (
    <textarea
      ref={ref}
      className={classNames(
        "w-full border border-default/25 px-4 py-3 leading-5 transition",
        "focus:border-default/50 focus:shadow-[0_0_0_4px] focus:shadow-default/10 focus-visible:outline-none",
        "disabled:border-default/40 disabled:bg-default/10",
        {
          "border-primary focus:border-primary focus:shadow-primary/10":
            isInvalid
        },
        className
      )}
      {...rest}
    />
  )
})

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & { isInvalid?: boolean }

export const Select = React.forwardRef<HTMLSelectElement | null, SelectProps>(
  (props, ref) => {
    const { className, isInvalid, children, ...rest } = props

    return (
      <select
        ref={ref}
        className={classNames(
          "w-full appearance-none border border-default/25 bg-[right_1rem_center] bg-no-repeat py-3 pl-4 pr-8 leading-5 transition enabled:bg-small-arrow-down",
          "focus:border-default/50 focus:shadow-[0_0_0_4px] focus:shadow-default/10 focus:outline-none ",
          "disabled:border-default/40 disabled:bg-default/10 disabled:opacity-100",
          {
            "border-primary focus:border-primary focus:shadow-primary/10":
              isInvalid
          },
          className
        )}
        {...rest}
      >
        {children}
      </select>
    )
  }
)
