import React, { PropsWithChildren } from "react"

import Header from "./header"
import Footer from "./footer"
import CookieConsentBanner from "./cookie-consent-banner"

type LayoutProps = PropsWithChildren<{
  pagePath: string
}>

export default function Layout(props: LayoutProps) {
  return (
    <main className="relative flex h-full min-h-screen flex-col">
      <Header pagePath={props.pagePath} />
      <main>{props.children}</main>
      <Footer />
      <CookieConsentBanner />
    </main>
  )
}
