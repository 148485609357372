import React from "react"
import { Link, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Options } from "@contentful/rich-text-react-renderer"
import {
  BLOCKS,
  INLINES,
  AssetLinkBlock,
  EntryLinkBlock,
  Quote,
  Hyperlink,
  Paragraph,
  ListItem
} from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  AssetCaption,
  ContentfulYouTubeVideo,
  HighlightedCode
} from "components"

export default function PostContent({ content }: Queries.PostContentFragment) {
  const contentRenderOptions: Options | undefined =
    content.references !== null
      ? {
          renderNode: {
            [BLOCKS.HEADING_1]: (_node, children) => (
              <h2 className="h3">{children}</h2>
            ),
            [BLOCKS.HEADING_2]: (_node, children) => (
              <h3 className="h4">{children}</h3>
            ),
            [BLOCKS.HEADING_3]: (_node, children) => (
              <h4 className="h5 !text-default">{children}</h4>
            ),
            [BLOCKS.PARAGRAPH]: (_node, children) => (
              <p className="mb-4 last:mb-0 md:mb-8">{children}</p>
            ),
            [BLOCKS.OL_LIST]: (_node, children) => (
              <ol className="mb-4 list-decimal pl-8 last:mb-0 md:mb-8 [p_+_&]:-mt-2 [p_+_&]:md:-mt-6">
                {children}
              </ol>
            ),
            [BLOCKS.UL_LIST]: (_node, children) => (
              <ul className="mb-4 list-disc pl-8 last:mb-0 md:mb-8 [p_+_&]:-mt-2 [p_+_&]:md:-mt-6">
                {children}
              </ul>
            ),
            [BLOCKS.LIST_ITEM]: node => {
              if (node.nodeType !== BLOCKS.LIST_ITEM) return null

              const { content } = node as ListItem

              return (
                <li className="mb-4 last:mb-0">
                  {content.map((node, index) =>
                    node.nodeType === BLOCKS.PARAGRAPH ? (
                      <span className="mb-4 block last:mb-0" key={index}>
                        {node.content
                          .map(node =>
                            node.nodeType === "text" ? node.value : null
                          )
                          .join(" ")}
                      </span>
                    ) : null
                  )}
                </li>
              )
            },
            [BLOCKS.EMBEDDED_ASSET]: node => {
              if (node.nodeType !== BLOCKS.EMBEDDED_ASSET) return null

              const { data } = node as AssetLinkBlock
              const target = data.target as Queries.PostAssetFragment &
                AssetLinkBlock["data"]["target"]

              if (target.__typename !== "ContentfulAsset") return null

              if (target.gatsbyImageData) {
                const { title, description: caption, gatsbyImageData } = target

                return (
                  <div className="mb-4 w-full last:mb-0 md:mb-8">
                    <GatsbyImage
                      className="w-full"
                      image={gatsbyImageData}
                      alt={title ?? "Image"}
                    />

                    {caption && <AssetCaption caption={caption} />}
                  </div>
                )
              }

              if (/^video/i.test(target.file.contentType)) {
                const {
                  description: caption,
                  file: { url, contentType }
                } = target

                return (
                  <div className="mb-4 w-full last:mb-0 md:mb-8">
                    <video style={{ width: "100%" }} controls>
                      <source src={url} type={contentType} />
                    </video>

                    {caption && (
                      <p className="bg-default/5 px-4 py-2 text-center text-sm">
                        {caption}
                      </p>
                    )}
                  </div>
                )
              }
            },
            [BLOCKS.EMBEDDED_ENTRY]: node => {
              if (node.nodeType !== BLOCKS.EMBEDDED_ENTRY) return null

              const { data } = node as EntryLinkBlock
              const target = data.target as NonNullable<
                Queries.PostContentFragment["content"]["references"]
              >[number] &
                EntryLinkBlock["data"]["target"]

              if (target.__typename === "ContentfulContainer") {
                const iconBlocks = target.items.reduce<
                  Queries.PostIconBlockFragment[]
                >((result, item) => {
                  if (item.__typename !== "ContentfulIconBlock") return result

                  return [...result, item]
                }, [])

                return (
                  <div className="mb-4 grid grid-cols-2 gap-x-6 last:mb-0 sm:grid-cols-3 md:mb-8">
                    {iconBlocks.map(
                      ({
                        title,
                        icon: {
                          title: iconTitle,
                          file: { url }
                        }
                      }) => (
                        <div className="text-center" key={title}>
                          <img
                            className="inline-block h-[5.5rem] p-3"
                            src={url}
                            alt={iconTitle ?? "Icon"}
                          />
                          <p className="text-xs/tight">{title}</p>
                        </div>
                      )
                    )}
                  </div>
                )
              }

              if (target.__typename === "ContentfulYouTubeVideo") {
                return (
                  <ContentfulYouTubeVideo
                    className="mb-4 last:mb-0 md:mb-8"
                    {...target}
                  />
                )
              }

              if (target.__typename === "ContentfulLinkWithImage") {
                const {
                  target: link,
                  image: { title, description: caption, gatsbyImageData }
                } = target

                if (!gatsbyImageData)
                  throw new Error(
                    "Failed to load image for ContentfulLinkWithImage content type."
                  )

                const image = (
                  <GatsbyImage
                    className="w-full"
                    image={gatsbyImageData}
                    alt={title ?? "Linked image"}
                  />
                )

                const linkIsInternal = /^\//.test(link)

                return (
                  <div className="mb-4 last:mb-0 md:mb-8">
                    {linkIsInternal ? (
                      <Link to={link}>{image}</Link>
                    ) : (
                      <a href={link}>{image}</a>
                    )}

                    {caption && <AssetCaption caption={caption} />}
                  </div>
                )
              }

              if (target.__typename === "ContentfulHighlightedCode") {
                return <HighlightedCode {...target} />
              }
            },
            [BLOCKS.QUOTE]: node => {
              if (node.nodeType !== BLOCKS.QUOTE) return null

              const { content } = node as Quote
              const [lastParagraph, ...quoteReversed] = content.reverse()
              const quote = quoteReversed.reverse()

              const renderParagraph = (paragraph: Paragraph) =>
                paragraph.content.map((node, index) =>
                  node.nodeType === "text" ? (
                    <p className="mb-4 last:mb-0" key={index}>
                      {node.value}
                    </p>
                  ) : undefined
                )

              const hasAuthor =
                lastParagraph.content[0].nodeType === "text" &&
                lastParagraph.content[0].marks.length > 0 &&
                lastParagraph.content[0].marks[0].type === "bold"

              const author =
                hasAuthor && lastParagraph.content[0].nodeType === "text"
                  ? lastParagraph.content[0].value
                  : undefined

              return (
                <blockquote className="relative mb-4 pb-2 text-lg/relaxed before:block before:content-quotemark last:mb-0 md:mb-8 md:pl-10 before:md:absolute before:md:left-0">
                  {hasAuthor ? (
                    <>
                      {quote.map(renderParagraph)}
                      <p className="text-sm font-bold">&mdash; {author}</p>
                    </>
                  ) : (
                    content.map(renderParagraph)
                  )}
                </blockquote>
              )
            },
            [INLINES.HYPERLINK]: (node, children) => {
              if (node.nodeType !== INLINES.HYPERLINK) return null

              const {
                data: { uri }
              } = node as Hyperlink

              const isInternal = /^(\/)/.test(uri)

              return isInternal ? (
                <Link to={uri}>{children}</Link>
              ) : (
                <a
                  href={uri}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {children}
                </a>
              )
            }
          }
        }
      : undefined

  return (
    <div>
      {content &&
        renderRichText(
          {
            raw: content.raw,
            references: content.references ? [...content.references] : []
          },
          contentRenderOptions
        )}
    </div>
  )
}

export const fragment = graphql`
  fragment PostContent on ContentfulPost {
    content {
      raw
      references {
        ...PostAsset
        ...PostIconBlockSet
        ... on ContentfulYouTubeVideo {
          __typename
          contentful_id
          ...YouTubeVideo
        }
        ... on ContentfulLinkWithImage {
          __typename
          contentful_id
          target
          image {
            title
            description
            gatsbyImageData
          }
        }
        ... on ContentfulHighlightedCode {
          __typename
          contentful_id
          ...HighlightedCode
        }
      }
    }
  }

  fragment PostAsset on ContentfulAsset {
    __typename
    contentful_id
    title
    description
    file {
      contentType
      url
    }
    gatsbyImageData
  }

  fragment PostIconBlockSet on ContentfulContainer {
    __typename
    contentful_id
    title
    items {
      __typename
      ...PostIconBlock
    }
  }

  fragment PostIconBlock on ContentfulIconBlock {
    __typename
    contentful_id
    title
    icon {
      title
      file {
        url
      }
    }
  }
`
