import React from "react"
import { graphql } from "gatsby"
import LiteYouTubeEmbed from "react-lite-youtube-embed"

import { AssetCaption } from "components"

import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

type YouTubeVideoProps = {
  id: string
  title: string
}

export function YouTubeVideo({ id, title }: YouTubeVideoProps) {
  return (
    <LiteYouTubeEmbed
      id={id}
      title={title}
      params="rel=0"
      poster="maxresdefault"
      webp
    />
  )
}

type ContentfulYouTubeVideoProps = {
  className?: string
} & Queries.YouTubeVideoFragment

export function ContentfulYouTubeVideo(props: ContentfulYouTubeVideoProps) {
  const { className, youTubeId, videoTitle, caption } = props

  return (
    <div className={className}>
      <YouTubeVideo id={youTubeId} title={videoTitle} />

      {caption && <AssetCaption caption={caption} />}
    </div>
  )
}

export const fragment = graphql`
  fragment YouTubeVideo on ContentfulYouTubeVideo {
    youTubeId
    videoTitle: title
    caption
  }
`
