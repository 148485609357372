import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PostMeta({
  author,
  publicationDate
}: Queries.PostMetaFragment) {
  const { name } = author
  const avatar = getImage(author.avatar)

  if (!avatar) throw new Error("Failed to load author's avatar for post meta.")

  return (
    <div className="mb-8 flex flex-wrap items-center gap-x-[0.3em] text-sm text-gray">
      <span>Written by</span>

      <div className="flex items-center gap-x-1">
        <GatsbyImage
          className="mx-0.5 aspect-square max-h-[1.5em] rounded-full"
          image={avatar}
          alt={name}
        />
        {name}
      </div>

      <span className="whitespace-nowrap">on {publicationDate}</span>
    </div>
  )
}

export const fragment = graphql`
  fragment PostMeta on ContentfulPost {
    publicationDate(formatString: "MMMM Do, YYYY")
    author {
      name
      avatar {
        gatsbyImageData(width: 24, height: 24, layout: FIXED)
      }
    }
  }
`
