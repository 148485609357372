import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ProjectCard from "./card"
import TextCard from "./text"

export default function ProjectCards() {
  const data = useStaticQuery<Queries.CaseStudyCardsQuery>(graphql`
    query CaseStudyCards {
      contentfulContainer(title: { eq: "Case Studies Cards" }) {
        items {
          __typename
          ...ProjectCard
          ...TextCard
        }
      }
    }
  `)

  if (data.contentfulContainer === null) {
    throw Error("Failed to load case study cards.")
  }

  const cards = data.contentfulContainer.items.reduce<
    (Queries.ProjectCardFragment | Queries.TextCardFragment)[]
  >((result, item) => {
    if (item.__typename === "ContentfulProjectCard") return [...result, item]
    if (item.__typename === "ContentfulTextCard") return [...result, item]

    return result
  }, [])

  return (
    <section className="mb-24 grid grid-cols-2 gap-6 md:mb-32 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
      {cards.map((card, index) => (
        <div key={index}>
          {card.__typename === "ContentfulProjectCard" ? (
            <ProjectCard {...card} />
          ) : (
            <TextCard {...card} />
          )}
        </div>
      ))}
    </section>
  )
}
