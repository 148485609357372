import React from "react"
import { Link, type GatsbyLinkProps } from "gatsby"
import classNames from "classnames"

type LinkNativeProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

type ButtonNativeProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type GatsbyLinkNativeProps<TState = undefined> = Omit<
  GatsbyLinkProps<TState>,
  "ref"
>

type ButtonCustomProps<T> = {
  color?: "primary" | "success" | "secondary"
  size?: "medium" | "large"
} & T

type ButtonProps = ButtonCustomProps<
  | ({ type: "external-link" } & LinkNativeProps)
  | ({ type: "link" } & GatsbyLinkNativeProps)
  | ButtonNativeProps
>

export default function Button(props: ButtonProps) {
  const { color = "primary", size = "medium", ...rest } = props

  const className = classNames(
    "font-bold text-white !no-underline inline-block relative overflow-hidden shadow-none shadow-black/20 !transition-all",
    {
      "bg-primary disabled:bg-primary/60": color === "primary",
      "bg-success disabled:bg-success/60": color === "success",
      "bg-default disabled:bg-default/60": color === "secondary",
      "py-2 px-4": size === "medium",
      "py-3 px-16": size === "large"
    }
  )

  const classNameSugar = classNames(
    "hover:-translate-y-0.5 hover:scale-105 hover:shadow-[0_10px_4px_-8px] hover:shadow-black/20",
    "after:bg-white/25 after:mix-blend-overlay after:absolute after:left-[calc(-50%_-_4rem)] after:top-0 after:z-0 after:h-[calc(1.125rem_*_1.625_+_1.5rem)] after:w-[calc(50%_+_2rem)] after:-skew-x-[30deg] after:transition-[left] hover:after:left-[calc(0%_-_2rem)]"
  )

  switch (rest.type) {
    case "link": {
      const {
        type,
        className: gatsbyLinkClassName,
        children,
        ...gatsbyLinkProps
      } = rest

      return (
        <Link
          {...gatsbyLinkProps}
          className={classNames(className, gatsbyLinkClassName, classNameSugar)}
        >
          {children}
        </Link>
      )
    }
    case "external-link": {
      const { type, className: linkClassName, children, ...linkProps } = rest

      return (
        <a
          {...linkProps}
          className={classNames(className, linkClassName, classNameSugar)}
        >
          {children}
        </a>
      )
    }
    default: {
      const { className: buttonClassName, children, ...buttonProps } = rest

      return (
        <button
          {...buttonProps}
          className={classNames(className, buttonClassName, {
            [classNameSugar]: !buttonProps.disabled
          })}
          type={rest.type ?? "button"}
        >
          <span className="relative z-[1] block"></span>
          {children}
        </button>
      )
    }
  }
}
