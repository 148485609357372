import React, { PropsWithChildren } from "react"
import { WithContext, Organization } from "schema-dts"
import { useStaticQuery, graphql, HeadProps } from "gatsby"

import { useSiteMetadata } from "hooks"
import { useOrganization } from "./structured-data"

type Props = PropsWithChildren<
  {
    title?: string
    description?: string
  } & HeadProps["location"]
>

export default function SEO({ title, description, pathname, children }: Props) {
  const {
    siteUrl,
    title: siteTitle,
    description: siteDescription
  } = useSiteMetadata()

  const pageUrl = siteUrl + pathname
  const metaTitle = title || siteTitle
  const metaDescription = description || siteDescription

  const logoQuery = useStaticQuery<Queries.SocialMediaLogosQuery>(
    graphql`
      query SocialMediaLogos {
        openGraphLogo: file(name: { eq: "open-graph-logo" }) {
          publicURL
        }
        twitterLogo: file(name: { eq: "twitter-logo" }) {
          publicURL
        }
      }
    `
  )

  if (
    logoQuery.openGraphLogo === null ||
    logoQuery.openGraphLogo.publicURL === null
  )
    throw new Error("Pieoneers logo for Open Graph metadata is missing")
  if (
    logoQuery.twitterLogo === null ||
    logoQuery.twitterLogo.publicURL === null
  )
    throw new Error("Pieoneers logo for Twitter metadata is missing")

  const openGraphLogoUrl = siteUrl + logoQuery.openGraphLogo.publicURL
  const twitterLogoUrl = siteUrl + logoQuery.twitterLogo.publicURL

  const organization = useOrganization()

  const schema: WithContext<Organization> = {
    "@context": "https://schema.org",
    ...organization
  }

  return (
    <>
      <html lang="en" />
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={openGraphLogoUrl} />
      <meta property="og:image:type" content="image/png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@pieoneers" />
      <meta name="twitter:creator" content="@pieoneers" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={twitterLogoUrl} />

      {children}

      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </>
  )
}
