import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { Button } from "components"
import { NavLink } from "./nav-link"

type DesktopNavProps = {
  links: NavLink[]
}

export default function DesktopNav(props: DesktopNavProps) {
  return (
    <nav>
      <menu className="flex items-stretch justify-center gap-3 self-stretch text-sm xl:gap-x-6">
        {props.links.map(link => (
          <li className={link.dropdown && "group relative"} key={link.slug}>
            <Link
              className="inline-flex h-full items-center whitespace-nowrap font-bold text-primary transition hover:shadow-[inset_0_-0.125rem_0] hover:shadow-primary"
              to={`/${link.slug}/`}
              activeClassName="shadow-[inset_0_-0.125rem_0_0] shadow-primary"
              partiallyActive
            >
              {link.title}
            </Link>

            {link.dropdown && (
              <ul
                className={classNames(
                  "absolute right-0 z-30 hidden space-y-8 bg-white py-8 shadow-xl shadow-black/20 group-hover:block",
                  {
                    "min-w-[24em]": link.dropdown.layout === "wide",
                    "min-w-[18em]": link.dropdown.layout === "narrow"
                  }
                )}
              >
                {link.dropdown.links.map(dropdownLink => (
                  <li
                    className={classNames("relative pr-6", {
                      "pl-32": link.dropdown?.layout === "wide",
                      "pl-20": link.dropdown?.layout === "narrow"
                    })}
                    key={dropdownLink.slug}
                  >
                    {dropdownLink.icon && (
                      <img
                        className={classNames(
                          "absolute left-6 top-[0.75em] max-h-8 -translate-y-1/2",
                          {
                            "max-w-[5rem]": link.dropdown?.layout === "wide",
                            "max-w-[2rem]": link.dropdown?.layout === "narrow"
                          }
                        )}
                        src={dropdownLink.icon}
                        alt={dropdownLink.title}
                      />
                    )}

                    <Link
                      className="block py-[0.2em] font-semibold leading-tight hover:underline"
                      to={`/${link.slug}/${dropdownLink.slug}/`}
                    >
                      {dropdownLink.title}
                    </Link>

                    {dropdownLink.subLinks && (
                      <ul className="list-[square] pl-[1.5em]">
                        {dropdownLink.subLinks.map(subLink => (
                          <li key={subLink.slug}>
                            <Link
                              className="block py-[0.2em] font-semibold leading-tight hover:underline"
                              to={`/${link.slug}/${subLink.slug}/`}
                            >
                              {subLink.title.substring(
                                0,
                                subLink.title.lastIndexOf(" ")
                              )}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}

        <li>
          <Button
            type="link"
            className="inline-flex h-full items-center whitespace-nowrap py-0"
            to="/contact/"
          >
            Contact
          </Button>
        </li>
      </menu>
    </nav>
  )
}
