import { useStaticQuery, graphql } from "gatsby"

export type SiteMetadata = NonNullable<
  NonNullable<Queries.SiteMetadataQuery["site"]>["siteMetadata"]
>

export default function useSiteMetadata() {
  const { site } = useStaticQuery<Queries.SiteMetadataQuery>(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            email
            contactsCA {
              ...SiteMetadata_CountyContacts
            }
            contactsUS {
              ...SiteMetadata_CountyContacts
            }
          }
        }
      }

      fragment SiteMetadata_CountyContacts on SiteSiteMetadataCountryContacts {
        country
        countryISO
        region
        locality
        postalCode
        street
        telephone
      }
    `
  )

  if (site === null || site.siteMetadata === null) {
    throw Error("Failed to load site metadata")
  }

  return site.siteMetadata
}
