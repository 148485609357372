import React from "react"
import { Link, graphql } from "gatsby"
import _orderBy from "lodash/orderBy"

import { PostPreview } from "components/post"

export default function BlogTopic({
  heading,
  posts
}: Queries.BlogTopicFragment) {
  const orderedPosts = _orderBy(posts, "publicationDate", "desc")

  return (
    <section>
      <div className="mx-auto mb-16 lg:w-2/3">
        <h2 className="mb-1 text-center">{heading}</h2>

        <p className="text-center">
          Read more on <Link to="/blog/">our blog</Link>.
        </p>
      </div>

      <div className="grid gap-16 md:grid-cols-2 lg:gap-24">
        {orderedPosts.map((post, index) => (
          <PostPreview {...post} key={index} />
        ))}
      </div>
    </section>
  )
}

export const fragment = graphql`
  fragment BlogTopic on ContentfulPostTopic {
    heading
    posts {
      ...PostPreview
    }
  }
`
