import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost"
import fetch from "isomorphic-fetch"

const cache = new InMemoryCache()
const link = new HttpLink({
  uri: process.env.GATSBY_API_URL,
  fetch
})
const client = new ApolloClient({
  cache,
  link
})

export default client
