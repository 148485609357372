import React from "react"
import { graphql } from "gatsby"

export default function Testimonial({
  author,
  position,
  text,
  photo
}: Queries.TestimonialFragment) {
  return (
    <div
      className="flex flex-col gap-x-6 gap-y-4 md:flex-row md:text-left"
      key={author}
    >
      <div>
        <img
          className="max-w-[10rem] rounded-full md:max-w-none"
          src={photo.file.url}
          alt={author}
        />
      </div>

      <div className="shrink-0 md:w-3/4">
        <p className="mb-6 font-light md:text-xl/9">{text.text}</p>

        <p className="text-sm">
          <strong>{author}</strong>, {position}
        </p>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment Testimonial on ContentfulTestimonial {
    author
    position
    text {
      text
    }
    photo {
      file {
        url
      }
    }
  }
`
