import React from "react"
import { Link, graphql } from "gatsby"
import classNames from "classnames"

export default function ProjectCard({
  slug,
  cardImage
}: Queries.ProjectCardFragment) {
  return (
    <Link
      className={classNames(
        "relative block aspect-square overflow-hidden bg-cover bg-center bg-no-repeat shadow-[0_0_0_0] shadow-default/25 transition",
        "hover:-translate-y-[5px] hover:scale-105 hover:shadow-[0_10px_4px_-8px] hover:shadow-default/25",
        "after:absolute after:left-[-114%] after:top-0 after:z-0 after:h-full after:w-[82%] after:skew-x-[-30deg] after:bg-white/25 after:mix-blend-overlay after:transition-[left]",
        "hover:after:left-[-32%]"
      )}
      to={`/case-studies/${slug}/`}
      style={{ backgroundImage: "url(" + cardImage.file.url + ")" }}
    />
  )
}

export const fragment = graphql`
  fragment ProjectCard on ContentfulProjectCard {
    __typename
    slug
    cardImage {
      file {
        url
      }
    }
  }
`
