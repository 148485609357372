import React from "react"
import { Link, graphql } from "gatsby"
import classNames from "classnames"

export default function ProjectTile({
  title,
  slug,
  description,
  logo,
  background
}: Queries.ProjectTileFragment) {
  return (
    <Link
      to={`/case-studies/${slug}/`}
      className={classNames(
        "relative block overflow-hidden bg-cover bg-center bg-no-repeat pb-[100%] shadow-[0_0_0_0] shadow-black/20 transition-all",
        "hover:-translate-y-1 hover:scale-[1.02] hover:shadow-[0_10px_4px_-8px] hover:shadow-black/20",
        "after:absolute after:left-[-114%] after:top-0 after:z-0 after:h-full after:w-[82%] after:skew-x-[-30deg] after:bg-white/25 after:mix-blend-overlay after:transition-[left] hover:after:left-[-32%]"
      )}
      style={{ backgroundImage: "url(" + background.file.url + ")" }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col">
        <div className="flex shrink grow-0 basis-1/2 items-end justify-center px-3 py-4">
          <img
            className="max-h-[80%] w-auto max-w-[60%]"
            src={logo.file.url}
            alt={title}
          />
        </div>
        <div className="w-full shrink grow basis-1/2 px-3 py-4 text-white">
          <p className="mx-auto max-w-[16em] text-center text-base/normal text-white lg:text-xl/relaxed">
            {description}
          </p>
        </div>
      </div>
    </Link>
  )
}

export const fragment = graphql`
  fragment ProjectTile on ContentfulProjectTile {
    title
    slug
    description
    logo {
      file {
        url
      }
    }
    background {
      file {
        url
      }
    }
  }
`
