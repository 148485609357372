import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"

type PaginationProps = {
  slug: string
  pageCount: number
  currentPage: number
  className?: string
}

export default function Pagination(props: PaginationProps) {
  const { slug, pageCount, currentPage, className } = props

  return (
    <nav
      className={classNames(
        "flex justify-center gap-2 border-t border-default/20 pt-2",
        className
      )}
    >
      {currentPage > 1 && (
        <Link
          className="group p-2"
          to={`${slug}${currentPage - 1 === 1 ? "" : `${currentPage - 1}/`}`}
          rel="prev"
        >
          <i>&larr;</i>{" "}
          <span className="hidden underline decoration-default/25 transition-colors group-hover:decoration-default/50 sm:inline">
            Previous
          </span>
        </Link>
      )}

      {Array.from({ length: pageCount }).map((_, index) => {
        const pageNumber = index + 1

        return currentPage === pageNumber ? (
          <span className="p-2 font-bold text-primary" key={index}>
            {pageNumber}
          </span>
        ) : (
          <Link
            className="p-2 underline decoration-default/25 transition-colors hover:decoration-default/50"
            to={`${slug}${pageNumber === 1 ? "" : `${pageNumber}/`}`}
            key={index}
          >
            {pageNumber}
          </Link>
        )
      })}

      {currentPage < pageCount && (
        <Link
          className="group p-2"
          to={`${slug}${currentPage + 1}/`}
          rel="next"
        >
          <span className="hidden underline decoration-default/25 transition-colors group-hover:decoration-default/50 sm:inline">
            Next
          </span>{" "}
          <i>&rarr;</i>
        </Link>
      )}
    </nav>
  )
}
