import React from "react"
import { graphql, Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Options } from "@contentful/rich-text-react-renderer"
import {
  BLOCKS,
  INLINES,
  EntryLinkInline,
  Hyperlink
} from "@contentful/rich-text-types"

import { Button } from "components"

type Props = {
  callToAction: NonNullable<Queries.PostCallToActionFragment["callToAction"]>
}

export default function PostCallToAction({ callToAction }: Props) {
  const callToActionRenderOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="group mb-4 last:mb-0 md:mb-8">{children}</p>
      ),
      [INLINES.EMBEDDED_ENTRY]: node => {
        if (node.nodeType !== INLINES.EMBEDDED_ENTRY) return null

        const { data } = node as EntryLinkInline
        const { title, link } =
          data.target as EntryLinkInline["data"]["target"] &
            Queries.PostCallToActionButtonFragment

        return (
          <>
            <Button
              type="link"
              className="mt-4 first:mt-0 group-last:mt-0"
              to={link}
              size="large"
              color="success"
            >
              {title}
            </Button>

            {link.includes("contact") && (
              <span className="block">
                or <a href="https://call.pieoneers.com">Book an Appointment</a>
              </span>
            )}
          </>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.nodeType !== INLINES.HYPERLINK) return null

        const {
          data: { uri }
        } = node as Hyperlink

        const isInternal = /^\//.test(uri)

        return isInternal ? (
          <Link to={uri}>{children}</Link>
        ) : (
          <a href={uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      }
    }
  }
  return (
    <div className="mt-24 border-t border-default/25 pt-24 text-center">
      {renderRichText(
        {
          raw: callToAction.raw,
          references: callToAction.references
            ? [...callToAction.references]
            : []
        },
        callToActionRenderOptions
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment PostCallToAction on ContentfulPost {
    callToAction {
      raw
      references {
        ...PostCallToActionButton
      }
    }
  }

  fragment PostCallToActionButton on ContentfulButton {
    __typename
    contentful_id
    title
    link
  }
`
