import React, { PropsWithChildren, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { Link } from "gatsby"
import useMeasure from "react-use-measure"
import { animated, useSpring } from "@react-spring/web"
import classNames from "classnames"

import { NavLink } from "./nav-link"

type MobileNavProps = { links: NavLink[]; pagePath: string }

export default function MobileNav(props: MobileNavProps) {
  const [drawerIsVisible, setDrawerVisibility] = useState(false)
  const openDrawer = () => setDrawerVisibility(true)
  const closeDrawer = () => setDrawerVisibility(false)

  return (
    <>
      <button
        className="inline-block h-10 w-20 rounded-full bg-primary text-white"
        onClick={openDrawer}
        type="button"
      >
        Menu
      </button>

      {drawerIsVisible && <MobileNavDrawer {...props} onClose={closeDrawer} />}
    </>
  )
}

type MobileNavDrawerProps = { onClose: () => void } & MobileNavProps

function MobileNavDrawer(props: MobileNavDrawerProps) {
  const [isVisible, setVisibility] = useState(false)

  const TRANSITION_DURATION = 150

  useEffect(() => {
    const initialBodyOverflow = document.body.style.overflow

    document.body.style.overflow = "hidden"

    setVisibility(true)

    return () => {
      document.body.style.overflow = initialBodyOverflow
    }
  }, [])

  const close = () => {
    setVisibility(false)
    setTimeout(props.onClose, TRANSITION_DURATION)
  }

  return createPortal(
    <div
      className={classNames(
        "fixed left-0 top-0 z-30 h-screen w-full overflow-hidden transition-colors",
        {
          "bg-transparent": !isVisible,
          "bg-white/75": isVisible
        }
      )}
    >
      <nav
        className={classNames(
          "absolute bottom-0 left-full top-0 flex w-max max-w-[calc(100vw_-_5.5rem)] flex-col items-start gap-y-8 bg-primary text-white transition-transform",
          { "-translate-x-full": isVisible }
        )}
      >
        <button
          className={classNames(
            "absolute right-full top-0 p-6 transition-opacity",
            { "opacity-0": !isVisible, "opacity-100": isVisible }
          )}
          onClick={close}
          type="button"
        >
          <svg className="h-10 w-10 fill-transparent" viewBox="0 0 40 40">
            <circle className="fill-primary" cx="20" cy="20" r="20" />
            <g className="stroke-white stroke-[1.5]" strokeLinecap="round">
              <line x1="12" y1="12" x2="28" y2="28" />
              <line x1="12" y1="28" x2="28" y2="12" />
            </g>
          </svg>
        </button>

        <menu className="flex h-full flex-col gap-y-4 overflow-y-scroll px-6 py-8 font-light sm:px-8">
          {props.links.map(link => (
            <li className="max-w-[40ch]" key={link.slug}>
              {link.dropdown ? (
                <Dropdown
                  primaryLink={
                    <Link
                      className="text-lg"
                      activeClassName="font-bold !no-underline cursor-default"
                      to={`/${link.slug}/`}
                      onClick={close}
                    >
                      {link.title}
                    </Link>
                  }
                  expandByDefault={link.dropdown.links.some(
                    dropdownLink =>
                      props.pagePath.includes(
                        `/${link.slug}/${dropdownLink.slug}`
                      ) ||
                      dropdownLink.subLinks?.some(subLink =>
                        props.pagePath.includes(`/${link.slug}/${subLink.slug}`)
                      )
                  )}
                >
                  {link.dropdown.links.map(dropdownLink => (
                    <li
                      className={classNames(
                        "relative overflow-visible pr-6 text-sm",
                        {
                          "pl-20 sm:pl-[6.5rem]":
                            link.dropdown?.layout === "wide",
                          "pl-12 sm:pl-14": link.dropdown?.layout === "narrow"
                        }
                      )}
                      key={dropdownLink.slug}
                    >
                      {dropdownLink.icon && (
                        <div
                          className={classNames(
                            "absolute left-0 top-[0.75em] -translate-y-1/2 bg-white",
                            "[mask-position:left_center] [mask-repeat:no-repeat] [mask-size:contain] [mask-type:alpha]",
                            {
                              "aspect-[5/2] w-16 sm:w-20":
                                link.dropdown?.layout === "wide",
                              "aspect-square w-8":
                                link.dropdown?.layout === "narrow"
                            }
                          )}
                          style={{
                            maskImage: `url(${dropdownLink.icon})`,
                            WebkitMaskImage: `url(${dropdownLink.icon})`
                          }}
                        />
                      )}

                      <Link
                        to={`/${link.slug}/${dropdownLink.slug}/`}
                        activeClassName="font-bold !no-underline cursor-default"
                        onClick={close}
                      >
                        {dropdownLink.title}
                      </Link>

                      {dropdownLink.subLinks && (
                        <ul className="mt-1 list-[square] pl-[1em] text-sm sm:pl-[1.5em]">
                          {dropdownLink.subLinks.map(subLink => (
                            <li key={subLink.slug}>
                              <Link
                                className="block"
                                activeClassName="font-bold !no-underline cursor-default"
                                to={`/${link.slug}/${subLink.slug}/`}
                                onClick={close}
                              >
                                {subLink.title.substring(
                                  0,
                                  subLink.title.lastIndexOf(" ")
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </Dropdown>
              ) : (
                <Link
                  className="text-lg"
                  activeClassName="font-bold !no-underline cursor-default"
                  to={`/${link.slug}/`}
                  onClick={close}
                >
                  {link.title}
                </Link>
              )}
            </li>
          ))}

          <li className="mt-auto pt-2">
            <Link
              className="block border border-white p-3 text-center text-lg font-normal"
              to="/contact/"
              onClick={close}
            >
              Contact
            </Link>
          </li>
        </menu>
      </nav>
    </div>,
    document.body
  )
}

type DropdownProps = PropsWithChildren<{
  primaryLink: JSX.Element
  expandByDefault: boolean
}>

function Dropdown(props: DropdownProps) {
  const [isExpanded, setExpansion] = useState(props.expandByDefault)
  const toggle = () => setExpansion(prev => !prev)

  const [ref, bounds] = useMeasure()

  const SPRING_CONFIG = { tension: 300, friction: 30 }

  return (
    <>
      <span className="flex items-baseline justify-between gap-x-6">
        {props.primaryLink}

        <button onClick={toggle} type="button">
          <svg
            className="h-4 w-4 fill-transparent stroke-white stroke-[1.5]"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            strokeLinecap="round"
          >
            <animated.path
              d={
                useSpring({
                  d: isExpanded ? "M0.75 0.75L15.25 15.25" : "M8 0.75L8 15.25",
                  config: SPRING_CONFIG
                }).d
              }
            />
            <animated.path
              d={
                useSpring({
                  d: isExpanded ? "M0.75 15.25L15.25 0.75" : "M0.75 8L15.25 8",
                  config: SPRING_CONFIG
                }).d
              }
            />
          </svg>
        </button>
      </span>

      <animated.span
        className="block overflow-y-hidden"
        style={useSpring({
          height: isExpanded ? bounds.height : 0,
          config: SPRING_CONFIG
        })}
      >
        <span className="block py-4" ref={ref}>
          <ul className="flex flex-col items-start space-y-6 border-l-2 border-white/25 py-1 pl-3 sm:pl-6">
            {props.children}
          </ul>
        </span>
      </animated.span>
    </>
  )
}
