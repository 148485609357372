exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-aos-web-design-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/aos-web-design-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-aos-web-design-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-bildlist-mobile-web-app-development-burnaby-tsx": () => import("./../../../src/pages/case-studies/bildlist-mobile-web-app-development-burnaby.tsx" /* webpackChunkName: "component---src-pages-case-studies-bildlist-mobile-web-app-development-burnaby-tsx" */),
  "component---src-pages-case-studies-bodycomp-imagine-ios-android-and-web-development-for-health-tech-company-in-vancouver-tsx": () => import("./../../../src/pages/case-studies/bodycomp-imagine-ios-android-and-web-development-for-health-tech-company-in-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-bodycomp-imagine-ios-android-and-web-development-for-health-tech-company-in-vancouver-tsx" */),
  "component---src-pages-case-studies-booth-ipad-app-web-development-toronto-tsx": () => import("./../../../src/pages/case-studies/booth-ipad-app-web-development-toronto.tsx" /* webpackChunkName: "component---src-pages-case-studies-booth-ipad-app-web-development-toronto-tsx" */),
  "component---src-pages-case-studies-cannametrics-ios-android-app-development-branding-for-cannabis-company-vancouver-tsx": () => import("./../../../src/pages/case-studies/cannametrics-ios-android-app-development-branding-for-cannabis-company-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-cannametrics-ios-android-app-development-branding-for-cannabis-company-vancouver-tsx" */),
  "component---src-pages-case-studies-embedding-project-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/embedding-project-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-embedding-project-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-flowmail-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/flowmail-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-flowmail-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-gamesheet-ios-development-web-design-branding-and-web-development-for-sports-tech-company-in-toronto-ontario-tsx": () => import("./../../../src/pages/case-studies/gamesheet-ios-development-web-design-branding-and-web-development-for-sports-tech-company-in-toronto-ontario.tsx" /* webpackChunkName: "component---src-pages-case-studies-gamesheet-ios-development-web-design-branding-and-web-development-for-sports-tech-company-in-toronto-ontario-tsx" */),
  "component---src-pages-case-studies-hootsuite-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/hootsuite-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-hootsuite-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-jevitty-ios-android-and-web-development-for-health-tech-company-in-vancouver-tsx": () => import("./../../../src/pages/case-studies/jevitty-ios-android-and-web-development-for-health-tech-company-in-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-jevitty-ios-android-and-web-development-for-health-tech-company-in-vancouver-tsx" */),
  "component---src-pages-case-studies-navarik-ux-ui-design-vancouver-tsx": () => import("./../../../src/pages/case-studies/navarik-ux-ui-design-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-navarik-ux-ui-design-vancouver-tsx" */),
  "component---src-pages-case-studies-pocketbook-web-design-web-development-switzerland-tsx": () => import("./../../../src/pages/case-studies/pocketbook-web-design-web-development-switzerland.tsx" /* webpackChunkName: "component---src-pages-case-studies-pocketbook-web-design-web-development-switzerland-tsx" */),
  "component---src-pages-case-studies-progressa-web-design-rails-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/progressa-web-design-rails-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-progressa-web-design-rails-development-vancouver-tsx" */),
  "component---src-pages-case-studies-prosmart-ios-app-development-kelowna-tsx": () => import("./../../../src/pages/case-studies/prosmart-ios-app-development-kelowna.tsx" /* webpackChunkName: "component---src-pages-case-studies-prosmart-ios-app-development-kelowna-tsx" */),
  "component---src-pages-case-studies-rst-android-mobile-app-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/rst-android-mobile-app-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-rst-android-mobile-app-development-vancouver-tsx" */),
  "component---src-pages-case-studies-seaspan-web-development-north-vancouver-tsx": () => import("./../../../src/pages/case-studies/seaspan-web-development-north-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-seaspan-web-development-north-vancouver-tsx" */),
  "component---src-pages-case-studies-sessions-mental-health-app-development-company-oxford-tsx": () => import("./../../../src/pages/case-studies/sessions-mental-health-app-development-company-oxford.tsx" /* webpackChunkName: "component---src-pages-case-studies-sessions-mental-health-app-development-company-oxford-tsx" */),
  "component---src-pages-case-studies-sosido-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/sosido-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-sosido-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-ted-web-design-wp-development-tsx": () => import("./../../../src/pages/case-studies/ted-web-design-wp-development.tsx" /* webpackChunkName: "component---src-pages-case-studies-ted-web-design-wp-development-tsx" */),
  "component---src-pages-case-studies-ubc-web-development-vancouver-tsx": () => import("./../../../src/pages/case-studies/ubc-web-development-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-ubc-web-development-vancouver-tsx" */),
  "component---src-pages-case-studies-viva-graphic-design-typography-vancouver-tsx": () => import("./../../../src/pages/case-studies/viva-graphic-design-typography-vancouver.tsx" /* webpackChunkName: "component---src-pages-case-studies-viva-graphic-design-typography-vancouver-tsx" */),
  "component---src-pages-case-studies-vk-web-development-europe-tsx": () => import("./../../../src/pages/case-studies/vk-web-development-europe.tsx" /* webpackChunkName: "component---src-pages-case-studies-vk-web-development-europe-tsx" */),
  "component---src-pages-case-studies-workrise-ios-android-app-development-sanfrancisco-tsx": () => import("./../../../src/pages/case-studies/workrise-ios-android-app-development-sanfrancisco.tsx" /* webpackChunkName: "component---src-pages-case-studies-workrise-ios-android-app-development-sanfrancisco-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-free-consultation-tsx": () => import("./../../../src/pages/free-consultation.tsx" /* webpackChunkName: "component---src-pages-free-consultation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oops-tsx": () => import("./../../../src/pages/oops.tsx" /* webpackChunkName: "component---src-pages-oops-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-career-opening-tsx": () => import("./../../../src/templates/career-opening.tsx" /* webpackChunkName: "component---src-templates-career-opening-tsx" */),
  "component---src-templates-legal-document-tsx": () => import("./../../../src/templates/legal-document.tsx" /* webpackChunkName: "component---src-templates-legal-document-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

