import React from "react"
import { Link, graphql } from "gatsby"

import { PostContent, PostMeta } from "./"

export default function PostPreview({
  slug,
  title,
  summary,
  author,
  publicationDate
}: Queries.PostPreviewFragment) {
  const to = `/blog/${slug}`

  return (
    <article>
      <h2 className="h3 mb-2">
        <Link className="decoration-default/25 hover:underline" to={to}>
          {title}
        </Link>
      </h2>

      <PostMeta author={author} publicationDate={publicationDate} />
      <PostContent content={summary} />

      <Link
        className="mt-4 inline-block font-bold underline decoration-default/25"
        to={to}
      >
        Continue reading
      </Link>
    </article>
  )
}

export const fragment = graphql`
  fragment PostPreview on ContentfulPost {
    slug
    title
    summary {
      raw
      references {
        ...PostAsset
      }
    }
    ...PostMeta
  }
`
