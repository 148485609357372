import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ProjectTile from "./tile"

type Props = {
  tileAmountToShow?: number
  exclude?: string
}

export default function ProjectTiles({ tileAmountToShow = 6, exclude }: Props) {
  const data = useStaticQuery<Queries.ProjectTilesQuery>(graphql`
    query ProjectTiles {
      contentfulContainer(title: { eq: "Project Tiles" }) {
        items {
          __typename
          ...ProjectTile
        }
      }
    }
  `)

  if (data.contentfulContainer === null) {
    throw Error("Failed to load project tiles.")
  }

  const tiles = data.contentfulContainer.items.reduce<
    Queries.ProjectTileFragment[]
  >((result, tile) => {
    if (tile.__typename !== "ContentfulProjectTile") return result

    return [...result, tile]
  }, [])

  const filteredTiles = tiles.filter(({ title }) => title !== exclude)
  const limitedTiles = filteredTiles.slice(0, tileAmountToShow)

  return (
    <div className="mb-8 grid gap-6 md:grid-cols-2">
      {limitedTiles.map(project => (
        <ProjectTile {...project} key={project.title} />
      ))}
    </div>
  )
}
