import { useEffect, useState } from "react"
import { isBrowser } from "utils"

type ScreenSizeMode = "desktop" | "mobile"

export default function useScreenSizeMode(
  breakpoint: Breakpoint = "lg"
): ScreenSizeMode {
  const defaultMode = isBrowser()
    ? window.innerWidth > BREAKPOINT_VALUE[breakpoint]
      ? "desktop"
      : "mobile"
    : "desktop"

  const [mode, setMode] = useState<ScreenSizeMode>(defaultMode)

  const updateMode = () => {
    if (isBrowser())
      setMode(
        window.innerWidth > BREAKPOINT_VALUE[breakpoint] ? "desktop" : "mobile"
      )
  }

  useEffect(() => {
    window.addEventListener("resize", updateMode)

    return () => window.removeEventListener("resize", updateMode)
  })

  return mode
}

type Breakpoint = "sm" | "md" | "lg" | "xl" | "2xl"

const BREAKPOINT_VALUE: { [key in Breakpoint]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  "2xl": 1400
}
