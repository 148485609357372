import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import { useScreenSizeMode } from "hooks"

import logo from "images/logo.svg"

import { NavDropdownLink, NavLink } from "./nav-link"
import DesktopNav from "./desktop-nav"
import MobileNav from "./mobile-nav"

type HeaderProps = { pagePath: string }

export default function Header(props: HeaderProps) {
  const { pagePath } = props

  const isIndexPage = pagePath === "/"
  const isCaseStudyPage =
    pagePath.startsWith("/case-studies/") && pagePath !== "/case-studies/"

  const screenSizeMode = useScreenSizeMode()

  const data = useStaticQuery<Queries.HeaderQuery>(graphql`
    query Header {
      services: contentfulContainer(title: { eq: "Services Page Tiles" }) {
        items {
          __typename
          ...ServiceTile
        }
      }
      careers: allContentfulCareerOpeningPage {
        nodes {
          title
          slug
          icon {
            file {
              url
            }
          }
        }
      }
    }
  `)

  if (data.services === null) {
    throw Error("Failed to load services for the header.")
  }

  const servicesDropdownLinks = data.services.items.reduce<NavDropdownLink[]>(
    (result, service) => {
      if (service.__typename !== "ContentfulServiceTile") return result

      const dropdwonLink: NavDropdownLink = {
        slug: service.slug,
        title: service.title,
        icon: service.icon?.file.url ?? null,
        subLinks: service.subServices?.map(subService => ({
          slug: subService.slug,
          title: subService.title
        }))
      }

      return [...result, dropdwonLink]
    },
    []
  )

  const careersDropdownLinks = data.careers.nodes.map(career => ({
    slug: career.slug,
    title: career.title,
    icon: career.icon?.file.url ?? null
  }))

  const navLinks: NavLink[] = [
    {
      slug: "services",
      title: "Services",
      dropdown: { links: servicesDropdownLinks, layout: "wide" }
    },
    { slug: "case-studies", title: "Case Studies" },
    { slug: "about", title: "About" },
    { slug: "blog", title: "Blog" },
    {
      slug: "careers",
      title: "Careers",
      dropdown: { links: careersDropdownLinks, layout: "narrow" }
    }
  ]

  return (
    <header
      className={classNames({
        "mb-12": !(isIndexPage || isCaseStudyPage),
        "absolute z-30 w-full backdrop-blur-sm": isIndexPage || isCaseStudyPage,
        "bg-white/90": isCaseStudyPage,
        "bg-white": isIndexPage
      })}
    >
      <div className="container flex flex-wrap items-center justify-between py-6">
        <Link className="shrink-0" to="/">
          <img className="max-h-10" alt="logo" src={logo} />
        </Link>

        <p className="ml-4 mr-auto translate-y-0.5 text-2xs uppercase tracking-wide text-gray max-md:hidden">
          AI, Web and Mobile App Development Company
        </p>

        {screenSizeMode === "desktop" && <DesktopNav links={navLinks} />}

        {screenSizeMode === "mobile" && (
          <MobileNav links={navLinks} pagePath={pagePath} />
        )}
      </div>
    </header>
  )
}
