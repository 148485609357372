import React, { PropsWithChildren } from "react"

type BlogLayoutProps = PropsWithChildren

export default function BlogLayout(props: BlogLayoutProps) {
  return (
    <div className="container">
      <div className="mx-auto lg:w-2/3">
        <p className="mb-16">
          Insights and updates on our mobile and web development and UX-UI.
          Thoughts on web design and a few things we love: Node.js, JavaScript,
          Typescript, React, Python, Django, iOS, Android, Docker, and
          Kubernetes. Innovation in health and wellness app development.
        </p>

        {props.children}
      </div>
    </div>
  )
}
