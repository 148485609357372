import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import TestimonialCarousel from "./carousel"

type Props = { initialSlide?: number }

export default function TeamTestimonialCarousel({ initialSlide = 0 }: Props) {
  const data = useStaticQuery<Queries.TeamTestimonialsQuery>(graphql`
    query TeamTestimonials {
      contentfulContainer(title: { eq: "Team Testimonials" }) {
        items {
          __typename
          ...Testimonial
        }
      }
    }
  `)

  if (data.contentfulContainer === null) {
    throw Error("Failed to load client testimonials.")
  }

  const testimonials = data.contentfulContainer.items.reduce<
    Queries.TestimonialFragment[]
  >((result, testimonial) => {
    if (testimonial.__typename !== "ContentfulTestimonial") return result

    return [...result, testimonial]
  }, [])

  return (
    <TestimonialCarousel
      testimonials={testimonials}
      initialSlide={initialSlide}
    />
  )
}
