import {
  Organization,
  ImageObject,
  ContactPoint,
  PostalAddress
} from "schema-dts"

import { useSiteMetadata } from "hooks"
import { graphql, useStaticQuery } from "gatsby"

export function useOrganization() {
  const { author, description, siteUrl, email, contactsCA, contactsUS } =
    useSiteMetadata()
  const logo = useLogo()

  const contactPoints: ContactPoint[] = [contactsCA, contactsUS].map(
    country => ({
      "@type": "ContactPoint",
      contactType: `Sales ${country.country}`,
      telephone: country.telephone,
      areaServed: country.countryISO
    })
  )

  const postalAddresses: PostalAddress[] = [contactsCA, contactsUS].map(
    country => ({
      "@type": "PostalAddress",
      addressCountry: country.countryISO,
      addressRegion: country.region,
      addressLocality: country.locality,
      postalCode: country.postalCode,
      streetAddress: country.street
    })
  )

  const organization: Organization = {
    "@type": "Organization",
    name: "Pieoneers - App Development",
    legalName: author,
    description: description,
    duns: "203518428",
    foundingDate: "2009-05-01",
    url: siteUrl,
    contactPoint: [
      {
        "@type": "ContactPoint",
        contactType: "Sales",
        email: email
      },
      ...contactPoints
    ],
    address: postalAddresses,
    location: postalAddresses,
    logo,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "22"
    },
    brand: {
      "@type": "Brand",
      name: "Pieoneers",
      slogan: "Expert Web and Mobile App Developers in Canada"
    }
  }

  return organization
}

function useLogo(): ImageObject {
  const { siteUrl } = useSiteMetadata()

  const logoQuery = useStaticQuery<Queries.LogoQuery>(
    graphql`
      query Logo {
        logo: file(name: { eq: "logo" }) {
          publicURL
        }
      }
    `
  )

  if (logoQuery.logo === null || logoQuery.logo.publicURL === null)
    throw new Error("Querying Pieoneers logo for the structured data failed")

  return {
    "@type": "ImageObject",
    url: siteUrl + logoQuery.logo.publicURL
  }
}
