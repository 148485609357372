import React from "react"

import { Button } from "components"

type Props = {
  title?: string
  description?: string
}

export default function GetInTouch({
  title = "What’s your great idea?",
  description = "Get in touch to discuss your project and see how Pieoneers can make it happen."
}: Props) {
  return (
    <div className="container pb-8 text-center before:mx-auto before:mb-[5.5rem] before:block before:h-[3px] before:w-full before:bg-gray-dark before:lg:mb-36 before:lg:w-[calc(66.66%_-_10px)]">
      <h2 className="sm:h1 mb-[0.25em]">{title}</h2>

      <p className="mb-10 text-xl sm:mb-12">{description}</p>

      <Button type="link" to="/contact/" color="success" size="large">
        Get In Touch
      </Button>

      <p className="mt-4">
        or <a href="https://call.pieoneers.com/">Book an Appointment</a>
      </p>
    </div>
  )
}
