import React, { PropsWithChildren } from "react"
import { Link } from "gatsby"

type Props = { to: string } & PropsWithChildren

export default function LinkBack({ to, children }: Props) {
  return (
    <p className="mb-4 text-primary">
      <i>&larr;</i>{" "}
      <Link
        className="no-underline hover:underline hover:decoration-primary"
        to={to}
      >
        {children}
      </Link>
    </p>
  )
}
